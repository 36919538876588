import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_CUSTOM_EVENT_USP, API_RESOURCE_CUSTOM_EVENTS } from 'Consts/apiResources';
import { ADMIN_CUSTOM_EVENT_USPS_MANAGE, ADMIN_CUSTOM_EVENTS } from 'Consts/routes';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelCustomEventUspEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            listCustomEvents: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}), 
            },
        }));
    }


    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => {
        return {
            ...data,
            customEvent: data.customEvent
                ? { value: data.customEvent.id, label: data.customEvent.title }
                : null,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history, location } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return actions.create({
            ...formState,
            customEventId: queryObject.customEventId || fromSelectObject(formState.customEventId),
            imageId: formState.image ? formState.image.id : undefined,
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_CUSTOM_EVENT_USPS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_CUSTOM_EVENT_USP].id }
                    )
                );
            });
    }

    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            customEventId: fromSelectObject(formState.customEvent),
            imageId: formState.image ? formState.image.id : undefined,
        });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { search } = location;
        const { formState } = this.state;

        const queryObject = parseQueryToObject(search, true);

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-custom-event-usps-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data && data.id),
                        label: 'id',
                        value: formState.id,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id),
                        title: 'Usuń USP z wydarzenia',
                        subtitle: 'USP zostanie usunięte',
                        buttonProps: {
                            onClick: () => actions.remove({ id: data && data.id }).then(() => {
                                return history.push(ADMIN_CUSTOM_EVENTS.path);
                            }),
                            children: 'Usuń',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'title',
                            label: 'Tytuł',
                        }, {
                            type: 'textarea',
                            name: 'content',
                            label: 'Treść',
                        }, {
                            isVisible: !Boolean(queryObject.customEventId),
                            type: 'select',
                            name: 'customEvent',
                            label: 'Wydarzenie',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listCustomEvents({ search: query }),
                                onMapResponse: response => response.payload[API_RESOURCE_CUSTOM_EVENTS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.title,
                                }),
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'image',
                            label: 'Zdjęcie',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'customEventUsp'),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}